import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { WrappedIntlProvider } from "./react-components/wrapped-intl-provider";
import registerTelemetry from "./telemetry";
import "./utils/theme";
import { HomePage } from "./react-components/home/HomePage";
import { AuthContextProvider } from "./react-components/auth/AuthContext";
import "./react-components/styles/global.scss";
import { ThemeProvider } from "./react-components/styles/theme";
import configs from "./utils/configs";
import { store } from "./utils/store-instance";
import { Auth0Provider } from "@auth0/auth0-react";

registerTelemetry("/home", `${configs.APP_NAME} Home Page`);

window.APP = { store };

function Root() {
  useEffect(() => {
    // Alpha keys A-I
    const components = document.createElement("script");
    components.src =
      configs.LIT_COMPONENTS_URL + "/my-media/index.js" ?? "https://components.onland.io/my-media/index.js";
    components.type = "module";
    components.async = true;
    components.defer = true;
    document.head.appendChild(components);
  }, []);

  return (
    <Auth0Provider
      domain={configs.AUTH0_DOMAIN}
      clientId={configs.AUTH0_CLIENT_ID}
      audience={configs.AUTH0_AUDIENCE}
      cookieDomain={configs.AUTH0_COOKIE_DOMAIN}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: configs.AUTH0_AUDIENCE
      }}
    >
      <WrappedIntlProvider>
        <ThemeProvider store={store}>
          <AuthContextProvider store={store}>
            <HomePage />
          </AuthContextProvider>
        </ThemeProvider>
      </WrappedIntlProvider>
    </Auth0Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("home-root"));
