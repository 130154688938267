import Cookies from "js-cookie";
import { getLocale } from "./utils/i18n";
import configs from "./utils/configs";

export function userHasAcceptedConsent() {
  return Cookies.get("cookie_consent") === "true";
}

export function initialize() {
  return new Promise(resolve => {
    const cookieConsentScript = document.createElement("script");
    cookieConsentScript.type = "text/javascript";
    cookieConsentScript.src = "https://www.termsfeed.com/public/cookie-consent/4.1.0/cookie-consent.js";

    // Append the external script to the document head
    document.head.appendChild(cookieConsentScript);

    const appName = configs.translation("app-name").toLowerCase().replace(/ /g, "-");

    const currentDomain = window.location.hostname;

    // Extract the root domain from the current domain
    const subdomainParts = currentDomain.split(".");
    const cookieDomain = "." + subdomainParts.slice(-2).join(".");

    // Wait for the external script to load before running the inline script
    cookieConsentScript.onload = function () {
      window.cookieconsent.run({
        notice_banner_type: "simple",
        consent_type: "express",
        palette: "light",
        page_load_consent_levels: ["strictly-necessary"],
        notice_banner_reject_button_hide: false,
        preferences_center_close_button_hide: false,
        page_refresh_confirmation_buttons: false,
        website_name: appName,
        cookie_domain: cookieDomain,
        website_privacy_policy_url: "/terms-and-conditions",
        language: getLocale() ?? "en"
      });

      resolve();
    };
  });
}
